import { DownloadFileItem, chunk, getFileSize } from "@plinknz/tah-website-elements";
import * as React from "react";
import { generate } from "shortid";

const MAX_GRID_AMOUNT = 3;

export type NewsGridTypeName =
    | "ComponentContentBlocksNewsGrid"
    | "ComponentContentBlocksPanuiGrid"
    | "ComponentMemberPageGrid";

interface DownloadPanelProps {
    title?: string;
    files?: DownloadFileItem[];
}

const renderRow = (files: DownloadFileItem[], index: number) => (
    <div className="news-grid-items" key={index}>
        {files.map((file) => (
            <a
                className="news-grid-item has-background"
                key={generate()}
                rel="noreferrer"
                target="_blank"
                style={{
                    backgroundImage: `url("${
                        file.image?.url ||
                        "/images/logo/ngati-rangitanga.png"
                    }")`,
                }}
                href={file.file.url}
                download>
                <h3 className="news-grid-item-title">
                    {file.name}
                    {file.file.size && (
                        <div className="links-block-item-metadata">
                            {getFileSize(file.file.size)}
                            <i className="links-block-item-icon fas fa-download fa-lg"></i>
                        </div>
                    )}
                </h3>
            </a>
        ))}
    </div>
);

export const DownloadPanels = ({ title, files }: DownloadPanelProps) => {
    return (
        <div className="news-grid" data-testid="news-grid">
            <div className="news-grid-wrapper || constrain-width">
                {title && <h3>{title}</h3>}
                {chunk<DownloadFileItem>(files, MAX_GRID_AMOUNT)
                    .map(renderRow)
                }
            </div>
        </div>
    );
};
